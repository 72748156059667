
.RecentBoxContainer {
	margin: 20px;
	display: grid;
	grid-gap: 30px;
	grid-template-columns: repeat(auto-fit, 330px);
}

.RecentOneBox {
	background-color: white;
	border-width: 1px;
	border-style: solid;
	border-color: #aaaaaa;
	border-radius: 6px;
	font-size: 20px;
	padding: 5px;
	text-align: left;
	border: 1px solid;
	width: 330px;
	position: relative;
}

.RecentBoxTitle {
	border-bottom: 1px solid;
	border-color: #aaaaaa;
	font-size: 16px;
	font-weight: bold;
	padding-bottom: 5px;
	cursor: pointer;
	user-select: none;
}

.RecentBoxDayContainer {
	display: grid;
	grid-template-columns: 1fr auto;
	align-items: center;
	justify-content: center;
}

.RecentBoxDayLeft {
	margin-left: 5px;
	font-size: 16px;
	text-align: left;
	width: fit-content;
}

.RecentBoxDayRight {
	font-size: 16px;
	justify-self: end;
}

.RecentMainToolbarContainer1 {
	display: grid;
	grid-template-columns: 1fr auto;
	height: 23px;
	background-color: #dddddd;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
}

.RecentMainToolbarLeft {
	font-size: 12px;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 10px;
	//min-width: 10px;
	text-align: left;
	margin-left: 10px;
	padding: 0px;
	width: fit-content;
}

.RecentMainToolbarRight {
	margin-right: 10px;
	justify-self: end;
}

.RecentMainToolbarContainer2 {
	display: flex;
	flex-direction: row;
}

.RecentMainToolbarText {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
}

.RecentMainToolbarLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

.RecentMainToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

.RecentBankStatementsDateHeader {
	font-size: 16px;
	font-weight: bold;
	width: 5vw;
	padding-left: 30px;
	padding-right: 10px;
}

.RecentBankStatementsDateItem {
	font-size: 16px;
	text-align: center;
	padding-left: 30px;
	padding-right: 10px;
}

.RecentBankStatementsPartnerHeader {
	font-size: 16px;
	font-weight: bold;
	width: 30vw;
	text-align: left;
	padding-left: 10px;
	padding-right: 10px;
}

.RecentBankStatementsPartnerItem {
	font-size: 16px;
	text-align: left;
	padding-left: 10px;
	padding-right: 10px;
}

.RecentBankStatementsDescriptionHeader {
	font-size: 16px;
	font-weight: bold;
	width: 30vw;
	text-align: left;
	padding-left: 10px;
	padding-right: 10px;
}

.RecentBankStatementsDescriptionItem {
	font-size: 16px;
	text-align: center;
	text-align: left;
	padding-left: 10px;
	padding-right: 10px;
}

.RecentBankStatementsPaidHeader {
	font-size: 16px;
	font-weight: bold;
	width: 10vw;
	text-align: right;
	padding-left: 10px;
	padding-right: 10px;
}

.RecentBankStatementsPaidItem {
	font-size: 16px;
	text-align: right;
	padding-left: 10px;
	padding-right: 10px;
}

.RecentBankStatementsReceivedHeader {
	font-size: 16px;
	font-weight: bold;
	width: 10vw;
	text-align: right;
	padding-left: 10px;
	padding-right: 10px;
}

.RecentBankStatementsReceivedItem {
	font-size: 16px;
	text-align: right;
	padding-left: 10px;
	padding-right: 10px;
}

.RecentBankStatementsIBANHeader {
	font-size: 16px;
	font-weight: bold;
	width: 10vw;
	text-align: left;
	padding-left: 10px;
	padding-right: 10px;
}

.RecentBankStatementsIBANItem {
	font-size: 16px;
	text-align: left;
	padding-left: 10px;
	padding-right: 10px;
}

.RecentBankStatementsIBANItemEmployee {
	color: blue;
	font-weight: bold;
	font-size: 16px;
	text-align: left;
	padding-left: 10px;
	padding-right: 10px;
}

.RecentBankStatementsIBANItemGovernment {
	color: orange;
	font-weight: bold;
	font-size: 16px;
	text-align: left;
	padding-left: 10px;
	padding-right: 10px;
}

.RecentBankStatementsIBANItemInvestment {
	color: #008080;
	font-weight: bold;
	font-size: 16px;
	text-align: left;
	padding-left: 10px;
	padding-right: 10px;
}

.RecentBankStatementsIBANItemBill1 {
	color: grey;
	font-size: 16px;
	text-align: left;
	padding-left: 10px;
	padding-right: 10px;
}

.RecentBankStatementsIBANItemBill2 {
	color: grey;
	font-weight: bold;
	font-size: 16px;
	text-align: left;
	padding-left: 10px;
	padding-right: 10px;
}

.RecentBankStatementsTransactionHeader {
	font-size: 16px;
	font-weight: bold;
	width: 10vw;
	padding-left: 10px;
	padding-right: 10px;
}

.RecentBankStatementsTransactionItem {
	font-size: 16px;
	text-align: center;
	padding-left: 10px;
	padding-right: 10px;
}

.RecentOccupancyBoxContainer {
	margin: 20px;
	display: grid;
	grid-gap: 30px;
	grid-template-columns: repeat(auto-fit, 330px);
}

.RecentOccupancyOneBox {
	background-color: white;
	border-width: 1px;
	border-style: solid;
	border-color: #aaaaaa;
	border-radius: 6px;
	font-size: 20px;
	padding: 5px;
	text-align: left;
	border: 1px solid;
	width: 330px;
	position: relative;
}

.RecentOccupancyBoxTitle {
	border-bottom: 1px solid;
	border-color: #aaaaaa;
	font-size: 16px;
	font-weight: bold;
	padding-bottom: 5px;
	cursor: pointer;
	user-select: none;
}

.RecentOccupancyBoxLineContainer {
	display: grid;
	grid-template-columns: 1fr 1fr;
	justify-self: end;
	align-items: center;
	justify-content: center;
}

.RecentOccupancyBoxLeft {
	margin-left: 5px;
	font-size: 16px;
}

.RecentOccupancyBoxRight {
	width: 250px;
	font-size: 16px;
	text-align: right;
}

.RecentBankStatementsShowAll {
    display: table-cell;
	color: black;
	font-size: 16px;
    padding: 5px;
	cursor: pointer;
    width: 170px;
	user-select: none;
}

.RecentBankStatementsDownloadLink {
	font-size: 16px;
    padding: 5px;
	cursor: pointer;
	user-select: none;
}

.RecentBankStatementsDownloadContainter {
    margin-left: auto;
    margin-right: auto;
}

.RecentBankStatementsMonthlyTable {
    margin-left: auto;
    margin-right: auto;
}

.RecentBankStatementsMonthlySumHeader {
	font-size: 16px;
	font-weight: bold;
	width: 140px;
	text-align: right;
	padding-left: 10px;
	padding-right: 10px;
}

.RecentBankStatementsMonthlySumItem {
	font-size: 16px;
	text-align: right;
	padding-left: 10px;
	padding-right: 10px;
}

.RecentBankStatementsMonthlyMonthHeader {
	font-size: 16px;
	font-weight: bold;
	width: 100px;
	text-align: left;
	padding-left: 10px;
	padding-right: 10px;
}

.RecentBankStatementsMonthlyMonthItem {
	font-size: 16px;
	text-align: left;
	padding-left: 10px;
	padding-right: 10px;
}

.RecentPartnerListIconHeader {
	font-size: 16px;
	font-weight: bold;
	width: 3vw;
	padding-left: 10px;
	padding-right: 10px;
}

.RecentPartnerListIconItem {
	font-size: 16px;
	text-align: center;
	padding-left: 10px;
	padding-right: 10px;
	cursor: pointer;
	user-select: none;
}

.RecentPartnerListNamesHeader {
	font-size: 16px;
	font-weight: bold;
	width: 50vw;
	text-align: left;
	padding-left: 10px;
	padding-right: 10px;
}

.RecentPartnerListNamesItem {
	font-size: 16px;
	text-align: left;
	padding-left: 10px;
	padding-right: 10px;
}

.RecentPartnerListIbansHeader {
	font-size: 16px;
	font-weight: bold;
	width: 30vw;
	text-align: left;
	padding-left: 10px;
	padding-right: 10px;
}

.RecentPartnerListIbansItem {
	font-size: 16px;
	text-align: left;
	padding-left: 10px;
	padding-right: 10px;
}

.RecentPartnerListTypeHeader {
	font-size: 16px;
	font-weight: bold;
	width: 10vw;
	padding-left: 10px;
	padding-right: 10px;
}

.RecentPartnerListTypeItem {
	font-size: 16px;
	text-align: center;
	padding-left: 10px;
	padding-right: 10px;
}

.RecentPartnerListEditNameInput {
	width: 40vw;
	font-size: 16px;
	text-align: left;
	padding-left: 10px;
	padding-right: 10px;
}

.RecentPartnerListEditIbanInput {
	width: 20vw;
	font-size: 16px;
	text-align: left;
	padding-left: 10px;
	padding-right: 10px;
}

.RecentPartnerListEditEmployeeInput {
	font-size: 20px;
	text-align: left;
	padding-left: 10px;
	padding-right: 10px;
}

