.DashboardEditTopContainer {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	justify-content: center;
}

.DashboardEditTopTopTitle {
	color: black;
	font-size: 30px;
	padding: 5px;
	margin-left: 20px;
	text-align: left;
}

.DashboardEditDateContainer {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	justify-self: end;
	align-items: center;
	justify-content: center;
}

.DashboardEditDatePrev {
	color: grey;
	font-size: 20px;
	padding: 15px;
	cursor: pointer;
	text-align: right;
	user-select: none;
}

.DashboardEditDateString {
	color: black;
	font-size: 16px;
	padding: 5px;
	cursor: pointer;
	text-align: center;
	width: 80px;
	user-select: none;
}

.DashboardEditDateNext {
	display: table-cell;
	color: grey;
	font-size: 20px;
	padding: 15px;
	cursor: pointer;
	text-align: left;
	user-select: none;
}

.DashboardEditContainer {
	padding-left: 20px;
	padding-right: 20px;
}

.DashboardEditFullName {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
	text-align: left;
}

.DashboardEditVariable {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
	width: 300px;
	text-align: right;
}

.DashboardEditRight {
	padding: 5px;
	font-size: 16px;
	//width: 300px;
}

.DashboardEditNumber {
	padding: 5px;
	font-size: 16px;
	width: 100px;
	text-align: right;
}

.DashboardEditText {
	padding: 5px;
	font-size: 16px;
	width: 500px;
	text-align: left;
}

.DashboardEditToolbarContainer1 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 23px;
	background-color: #dddddd;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
}

.DashboardEditToolbarLeft {
	font-size: 12px;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 10px;
	min-width: 10px;
	text-align: left;
	margin-left: 10px;
	padding: 0px;
	width: fit-content;
}

.DashboardEditToolbarRight {
	margin-right: 10px;
	justify-self: end;
}

.DashboardEditToolbarContainer2 {
	display: flex;
	flex-direction: row;
}

.DashboardEditToolbarText {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
}

.DashboardEditToolbarLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

.DashboardEditToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

.DashboardEditMonthlyTopHeaderItem1 {
	padding: 20px;
	font-size: 20px;
	cursor: pointer;
	position: relative;
	width: fit-content;
	justify-self: end;
	//text-align: right;
	user-select: none;
}

