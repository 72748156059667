.SelectDashboardTopContainer {
    padding-left: 10px;
    padding-right: 10px;
}

.SelectDashboardQuestionText {
	font-size: 16px;
    padding: 10px;
	cursor: pointer;
	user-select: none;
}

.DashboardListTopContainer {
}

.DashboardListToolbarContainer1 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 23px;
	background-color: #dddddd;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
}

.DashboardListToolbarLeft {
	font-size: 12px;
	padding: 2px;
	margin: 0px 0px 0px 10px;
	min-width: 10px;
	text-align: left;
	margin-left: 10px;
	padding: 0px;
	width: fit-content;
}

.DashboardListToolbarRight {
	margin-right: 10px;
	justify-self: end;
}

.DashboardListToolbarContainer2 {
	display: flex;
	flex-direction: row;
}

.DashboardListToolbarText {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
}

.DashboardListToolbarLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

.DashboardListToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

.DashboardNewFieldset {
	border-color: grey; 
	border-style: solid;
	border-width: 1px;
	border-color: #aaaaaa;
	border-radius: 6px;
	box-sizing: border-box; 
	grid-area: 1 / 1; /* first row, first column */
	width: 530px;
}

.DashboardNewLegend {
	font-size: 12px;
	color: grey;
	text-align: left;
	padding-left: 5px;
	padding-right: 5px;
}

.DashboardNewInput {
	border: 0px;
	font-size: 16px;
	width: 650px;
}

.DashboardNewInput:focus {
    outline: none;
}

.ToolbarPopupMenuContainer1 {
	width: 300px;
	position: absolute;
	background: #fff;
	border: 1px solid #d0d0d0;
	padding: 20px;
	left: 0px;
	top: 5px;
	z-index: 1;
	position: relative;
}

.ToolbarPopupMenuContainer {
	margin: 5px;
	width: 100%;
}

.ToolbarPopupMenuOneLink {
	color: grey;
	font-size: 20px;
	text-align: center;
	cursor: pointer;
	user-select: none;
}

