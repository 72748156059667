.YearlyRevenueTopContainer {
	background-color: #f7f7f7;
}

.YearlyRevenueToolbarContainer1 {
	display: grid;
	grid-template-columns: 1fr auto;
	height: 23px;
	background-color: #dddddd;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
}

.YearlyRevenueToolbarLeft {
	font-size: 12px;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 10px;
	//min-width: 10px;
	text-align: left;
	margin-left: 10px;
	padding: 0px;
	width: fit-content;
}

.YearlyRevenueToolbarRight {
	margin-right: 10px;
	justify-self: end;
}

.YearlyRevenueToolbarContainer2 {
	display: flex;
	flex-direction: row;
}

.YearlyRevenueToolbarText {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
}

.YearlyRevenueToolbarLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

.YearlyRevenueToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
	user-select: none;
}

.YearlyRevenueTopHeaderContainer {
	padding: 10px;
}

.YearlyRevenueTopHeaderOneYear {
	display: table-cell;
	font-size: 20px;
	cursor: pointer;
	user-select: none;
	padding: 5px;
}

.YearlyRevenueTopHeaderOneYearSelected {
	display: table-cell;
	font-size: 20px;
	font-weight: bold;
	cursor: pointer;
	user-select: none;
	padding: 5px;
}

.YearlyRevenueFooterContainer {
    margin-left: auto;
    margin-right: auto;
	width: 300px;
	align-items: center;
}

.YearlyRevenueFooterDiv {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
	grid-column-gap: 5px;
	grid-row-gap: 0px;
	margin: 0px;
}

.YearlyRevenueFooterTitle1 {
	background: #aaaaaa;
	color: white;
	font-size: 14px;
	font-weight: bold;
	padding: 5px;
}

.YearlyRevenueFooterTitle2 {
	background: #aaaaaa;
	color: white;
	font-size: 14px;
	font-weight: bold;
	padding: 5px;
	text-align: right;
}

.YearlyRevenueFooterItem1 {
	font-size: 14px;
	font-weight: bold;
	padding: 5px;
	text-align: center;
}

.YearlyRevenueFooterItem2 {
	font-size: 14px;
	font-weight: bold;
	padding: 5px;
	text-align: right;
}

.YearlyRevenueCustomTooltip {
	border-style: solid;
	border-width: thin;
	border-color: grey;
	background-color: white;
	padding: 15px 15px 15px 15px;
	user-select: none;
}

