
.CompareDatePickerContainer1 {
	margin: 5px;
	justify-content: space-between;
}

.CompareDatePickerContainer2 {
	font-size: 16px;
	cursor: pointer;
	position: relative;
	user-select: none;
}

.CompareDashboardPickerContainer1 {
	margin: 5px;
}

.CompareDashboardPickerContainer2 {
	font-size: 16px;
	cursor: pointer;
	user-select: none;
}

.CompareVariableHeader {
	font-size: 16px;
	font-weight: bold;
	width: 30vw;
}

.CompareValue1Header {
	font-size: 16px;
	width: 30vw;
}

.CompareValue2Header {
	font-size: 16px;
	width: 30vw;
}

.CompareValueLine1 {
	background: #e0dafa;
}

.CompareValueLine2 {
	background: #eaeaea;
}

.CompareURL {
	color: blue;
	cursor: pointer;
	user-select: none;
}
