
.SelectDashboardPopupMenuContainer1 {
	width: fit-content;
	position: absolute;
	background: #fff;
	border: 1px solid #d0d0d0;
	padding: 20px;
	left: 0px;
	top: 5px;
	z-index: 1;
	position: relative;
}

.SelectDashboardPopupMenuContainer {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
	margin: 5px;
	width: 100%;
}

.SelectDashboardPopupMenuDashboards {
	font-size: 20px;
	align-self: start;
	text-align: left;
	cursor: pointer;
	user-select: none;
}

.SelectDashboardPopupMenuOneDashboard {
	color: grey;
	font-size: 20px;
	text-align: left;
}

